@import "../../styles/_chunk";

.gift-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  background-color: $grey-16;
  border-radius: $border-radius-sm;
  padding: 0.5rem 1rem;

  &.gift-error {
    background-color: transparent;

    .gift-toggle-container {
      color: $grey-5 !important;

      svg {
        color: $grey-5;
      }
    }
  }

  @include theme(light) {
    background-color: $grey-3;
    color: $text-black;
  }

  .checkbox-container {
    margin-right: 0.2rem;

    input[type="checkbox"] {
      height: 13px;
      width: 13px;
    }

    input[type="checkbox"]:disabled {
      cursor: not-allowed;
      background-color: $grey-8;
    }
  }

  svg {
    margin-right: 0.35rem;
  }

  .info-circle-icon {
    height: 13px;
    width: 13px;
    margin-left: 0.5rem;
  }

  .gift-button-information-container {
    display: flex;
  }

  .text-button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 400;
    font-size: 0.85rem;

    @include media-breakpoint-up(md) {
      font-size: 0.9375rem;
    }

    .text-btn-button {
      border: none;
      background: none;
      font-weight: unset;
      padding: 0;

      .reduce-margin-bottom {
        margin-bottom: -0.3rem !important;
      }
    }

    .gift-toggle-container {
      background: none;
      display: flex;
      text-decoration: none;
      border: none;
      padding: 0;
      align-items: center;
      color: $text-white;
      font-weight: 300;

      @include theme(light) {
        color: map-get($light-theme, font-color);
      }
    }

    .edit-gift-button {
      padding: 0;
      text-decoration: underline;
      border: none;
      font-size: 0.8125rem;
      white-space: nowrap;
    }
  }

  .mobile-information-container {
    text-align: left;
    font-weight: 100;
    display: block;
    width: 100%;
    padding-top: 0.5rem;
    color: map-get($dark-theme, font-color);

    @include theme(light) {
      color: map-get($light-theme, font-color);
      font-weight: 400;
    }

    @include media-breakpoint-only(md) {
      font-size: 0.75rem;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .information-container {
    position: relative;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    &:hover {
      .tooltip-container {
        @include media-breakpoint-up(lg) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .tooltip-container {
      position: absolute;
      top: -9px;
      left: 4px;
      right: 0;
      padding-top: 2rem;
      display: none;
      z-index: 1;
      transition: all ease 0.2s;

      .tooltip-arrow {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $grey-2;
        z-index: 1;

        @include theme(light) {
          border-bottom: 8px solid $white;
        }
      }

      .tooltip-main-body {
        background-color: $grey-2;
        padding: 0.5rem;
        width: 220px;
        border-radius: 3px;
        box-shadow: map-get($light-theme, card-box-shadow);

        @include theme(light) {
          background-color: map-get($light-theme, card-background);
        }

        .tool-tip-text {
          text-align: center;
          font-size: 14px;
          color: $text-black;
          font-weight: 400;
        }
      }
    }
  }
}
