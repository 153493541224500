@import "../../styles/_chunk";

.safe-and-secure {
  background-color: $card-background-dark;
  border-radius: $border-radius;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  img {
    width: 100%;
    object-fit: contain;
  }

  .payment-img-grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -2px;
    margin-right: -2px;
    width: 100%;

    @include media-breakpoint-down(xl) {
      max-width: 270px;
    }

    .payment-img-column {
      padding: 2px;
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;

      .payment-img {
        width: 64px;
        height: 40px;
      }
    }

    @include theme(light) {
      .payment-img {
        border: 1px solid $grey-3;
        border-radius: $border-radius-sm;
      }
    }
  }
}
