@import "../../styles/_chunk";

.money-back-guarantee {
  background-color: $card-background-dark;
  border-radius: $border-radius;
  margin-bottom: 2rem;
  padding: 1rem;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  .icon-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    .icon-container {
      width: 44px;
      margin-right: 0.5rem;

      .guarantee-img {
        height: 37px;
        width: 45px;
      }
    }

    .title-container {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .message-container {
    font-size: 14px;
  }
}
