@import "../../styles/_chunk";

.checkout-upsell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .new-cart-upsell {
    background-color: $grey-13;
    border: 2px solid $success-core;
    margin-bottom: 2rem;
    position: relative;
    width: 100%;

    @include theme(light) {
      background-color: map-get($light-theme, card-background) !important;
    }

    button.upsell-container {
      width: 100%;
      text-align: left;
      color: $text-white;
      font-weight: 300;

      @include theme(light) {
        color: map-get($light-theme, font-color) !important;
      }
    }

    .upsell-container {
      display: flex;
      align-items: center;

      .message-container,
      .button-container {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.75rem;
        padding-right: 0.75rem;

        @include media-breakpoint-up(xl) {
          padding-left: 0.875rem;
          padding-right: 0.875rem;
        }

        @include media-breakpoint-up(xxl) {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .message-container {
        flex-grow: 1;

        .details-title {
          font-weight: 700;
          margin-bottom: 0.25rem;
          font-size: 1.125rem;
          color: $success-core;
          text-transform: uppercase;

          @include media-breakpoint-up(xl) {
            font-size: 1.25rem;
          }
        }

        .details-message {
          font-size: 0.875rem;

          @include media-breakpoint-up(lg) {
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }

      .button-container {
        .btn {
          font-weight: 700;
          text-transform: uppercase;

          .btn-text-container {
            display: none;

            @include media-breakpoint-up(md) {
              display: block;
            }
          }

          .small-btn-text-container {
            @include media-breakpoint-up(md) {
              display: none;
            }
          }
        }
      }
    }

    .upsell-pnm-collapse-container {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
      margin-bottom: 3rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
      }

      .pnm-upsell-slider {
        position: relative;
        margin-left: -5px;
        margin-right: -5px;
        width: 96%;

        @include media-breakpoint-up(sm) {
          margin-left: -8px;
          margin-right: -8px;
        }

        @include media-breakpoint-up(md) {
          margin-left: -10px;
          margin-right: -10px;
          width: 94%;
        }

        .slick-slide {
          padding: 0 5px;

          @include media-breakpoint-up(sm) {
            padding: 0 8px;
          }

          @include media-breakpoint-up(md) {
            padding: 0 10px;
          }
        }
      }
    }

    &.pnm-upsell {
      @include brand-property(border-color, brand-core, important);

      .upsell-container {
        .message-container {
          .details-title {
            @include brand-property(color, brand-core);
          }
        }
      }
    }
  }
}

.RedesignedMysteryUpsell {
  border: 2px solid $success-core;
  border-radius: $border-radius-sm;
  margin-bottom: 1rem;
  background-color: $card-background-dark;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  .RedesignedMysteryUpsell__title {
    background-color: $success-core;
    text-align: center;
    color: $black;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
  }

  .RedesignedMysteryUpsell__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;

    .RedesignedMysteryUpsell__leftContent {
      .RedesignedMysteryUpsell__message {
        font-weight: 400;
      }

      .RedesignedMysteryUpsell__logos {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        font-size: 12px;

        .RedesignedMysteryUpsell__logos__operatingSystem {
          @include theme(light) {
            color: map-get($light-theme, card-icons-color);
          }
        }

        .badges-container {
          margin-top: 0.5rem;
        }
      }
    }

    .RedesignedMysteryUpsell__rightContent {
      display: flex;
      align-items: center;

      .RedesignedMysteryUpsell__price {
        color: $primary-core;
        font-weight: 700;
      }

      .RedesignedMysteryUpsell__addButtonContainer {
        .RedesignedMysteryUpsell__addButton {
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
  }
}
