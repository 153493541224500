@import "../../styles/_chunk";

.checkout-item {
  display: flex;
  align-items: center;
  background-color: $card-background-dark;
  border-radius: $border-radius-sm;
  margin-bottom: 5px;
  padding: 0.75rem 1rem;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  @include media-breakpoint-up(md) {
    padding: 5px;
  }

  .item-image {
    display: none;
    background-color: $grey-13;
    flex-shrink: 0;
    margin-right: 1rem;
    border-radius: $border-radius-sm;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      display: flex;
      width: 140px;
    }

    @include media-breakpoint-up(xl) {
      width: 150px;
    }

    @include media-breakpoint-up(xxl) {
      width: 160px;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .item-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .details-container {
      flex-grow: 1;

      .promotional-item {
        color: $success-core;
        font-weight: 700;
        margin-bottom: 0.25rem;
      }

      button.promotional-item {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        text-decoration: underline;
      }

      .name-container {
        font-size: 0.9375rem;
        margin-bottom: 0;

        a {
          color: $text-white;

          @include theme(light) {
            color: map-get($light-theme, font-color);
          }
        }
      }

      .icons-labels-container {
        display: block;
        flex-direction: row;
        align-items: center;

        @include media-breakpoint-up(md) {
          display: flex;
          flex-wrap: wrap;
        }

        .icons-container {
          display: flex;
          align-items: center;
          margin-top: 0.5rem;

          .os-icons-container {
            display: flex;
            font-size: 12px;

            @include theme(light) {
              color: map-get($light-theme, card-icons-color);
            }

            svg {
              margin-right: 0.5rem;
            }
          }
        }

        .badges-container {
          margin-top: 0.5rem;
        }
      }

      .region-list-item {
        margin-top: 0.5rem;
        font-size: 13px;

        .notification-icon {
          margin-right: 0.25rem;
        }
      }

      .free-item-warning-text {
        margin-top: 0.5rem;
        font-size: 13px;
        color: $grey-4;
      }

      .pnm-dropdown-btn {
        color: $primary-core;
        padding: 0;
        margin: 0;
        border: 0;
        background: transparent;
        font-weight: 400;
        margin-top: 0.5rem;

        svg {
          margin-left: 0.375rem;
        }
      }
    }

    .quantity-price-delete-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      @include media-breakpoint-up(sm) {
        margin-right: 0.5rem;
      }

      @include media-breakpoint-up(md) {
        margin-right: 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        margin-right: 1rem;
      }

      .quantity-container {
        color: $white;
        text-align: left;
        font-size: 13px;
        line-height: 13px;
        margin-left: 0.75rem;

        @include media-breakpoint-up(lg) {
          margin-left: 1rem;
        }

        label {
          margin-bottom: 0.25rem;
          margin-right: 0.25rem;
        }

        svg {
          margin-right: 0.5rem;

          &:last-of-type {
            margin-right: 0;
          }
        }

        select {
          height: 26px;
          font-size: 11px;
          border-color: $grey-4;
          border-radius: $border-radius;

          @include theme(light) {
            border-color: $grey-3;
          }

          @include media-breakpoint-up(sm) {
            height: 30px;
            font-size: 12px;
          }

          @include media-breakpoint-up(md) {
            height: 34px;
            font-size: 13px;
          }

          &:focus {
            outline: 0;
          }
        }
      }

      .discount-price-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 0.75rem;

        @include media-breakpoint-up(lg) {
          margin-left: 1rem;
          min-width: 120px;
        }
      }

      .mobile-block-container {
        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      .price-was-price-container {
        color: red;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include media-breakpoint-up(lg) {
          min-width: 52px;
        }
      }

      .price-container {
        @include brand-property(color, brand-core);

        font-weight: 700;
        text-align: right;
        text-transform: uppercase;
      }

      .was-price-container {
        text-decoration: line-through;
        color: $grey-5;
        text-align: right;
        display: none;
        line-height: 18px;

        @include media-breakpoint-up(sm) {
          display: block;
        }
      }

      .discount-container {
        background-color: $black;
        border-radius: $border-radius;
        color: $text-white;
        padding: 5px 6px;
        min-width: 50px;
        text-align: center;
        display: none;
        margin-right: 0.75rem;
        height: 34px;

        @include theme(light) {
          background-color: map-get($light-theme, saving-percentage-background);
          color: map-get($light-theme, font-color);
        }

        @include media-breakpoint-up(sm) {
          display: block;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 1rem;
        }
      }

      .remove-button-container {
        margin-left: 1rem;

        .btn-primary {
          text-transform: uppercase;
          font-weight: 700;

          @include media-breakpoint-down(lg) {
            padding: 0.25rem 0.5rem;
            font-size: 13px;
          }
        }

        .cart-remove {
          transition: all 0.2s ease-in-out;

          @include theme(light) {
            color: $grey-5;
          }

          &:hover {
            @include brand-property(color, brand-core);

            cursor: pointer;
          }
        }
      }

      .edit-price-button-container {
        button {
          text-decoration: underline;

          @include media-breakpoint-down(sm) {
            padding-right: 0;
          }
        }
      }
    }

    .age-icon-container {
      width: 28px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
