@import "../../styles/_chunk";

.basket-summary {
  background-color: $card-background-dark;
  border-radius: $border-radius;
  padding: 1rem;
  margin-bottom: 2rem;
  font-size: 1rem;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  h4 {
    margin-bottom: 0.75rem;
  }
}

// NEW

.new-checkout {
  .btn-payment {
    text-transform: uppercase;
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    cursor: pointer;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .text-icon-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .lock-icon,
    .fa-spin {
      margin-top: -2px;
      font-size: 16px;
    }

    .btn-text {
      flex-grow: 1;
    }
  }

  .empty-cart {
    text-align: center;
    background-color: $card-background-dark;
    margin-bottom: 2rem;
    padding: 2rem;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    @include media-breakpoint-up(md) {
      padding: 2.5rem;
    }

    .btn {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .checkout-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;

    .lock-icon {
      margin-right: 0.75rem;
      font-size: 24px;
    }
  }

  .checkout-main-area-container {
    display: flex;
    flex-direction: column;
    margin: 0 -0.5rem;

    @include media-breakpoint-up(md) {
      margin: 0 -0.75rem;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 -0.875rem;
    }

    @include media-breakpoint-up(xxl) {
      margin: 0 -1rem;
    }

    .main-container {
      width: 100%;
      padding: 0 0.5rem;

      @include media-breakpoint-up(md) {
        padding: 0 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        width: 68%;
      }

      @include media-breakpoint-up(xl) {
        width: 72%;
        padding: 0 0.875rem;
      }

      @include media-breakpoint-up(xxl) {
        width: 75%;
        padding: 0 1rem;
      }

      .checkout-items {
        margin-bottom: 2rem;

        .item-message {
          background-color: $blue-grey-800;
          color: $white;
          font-size: 13px;
          font-weight: 400;
          padding: 1px 5px;
          text-align: center;

          &.warning {
            background-color: $error-core;
          }

          button {
            color: $white;
            text-transform: uppercase;
            font-weight: 700;
          }
        }

        &.reduced-margin-bottom {
          margin-bottom: 1rem;
        }
      }

      .cart-smallprint {
        font-size: 0.875rem;
        line-height: 1.5rem;
        color: $grey-4;

        @include theme(light) {
          color: $grey-7;
        }
      }
    }

    .side-container {
      width: 100%;
      padding: 0 0.5rem;

      @include media-breakpoint-up(md) {
        padding: 0 0.75rem;
      }

      @include media-breakpoint-up(lg) {
        width: 32%;
      }

      @include media-breakpoint-up(xl) {
        width: 28%;
        padding: 0 0.875rem;
      }

      @include media-breakpoint-up(xxl) {
        width: 25%;
        padding: 0 1rem;
      }

      .new-basket {
        font-size: 1rem;

        .summary-line-container {
          color: $grey-4;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.3125rem;

          @include theme(light) {
            color: $grey-7;
          }

          &.line-breaker {
            border-top: 1px solid $grey-8;
            padding-top: 0.5rem;
            margin-top: 0.625rem;
            color: $white;

            @include theme(light) {
              color: map-get($light-theme, font-color);
              border-color: $grey-3;
            }
          }

          &.small-print {
            font-size: 0.8125rem;
          }

          &.checkout-full-price {
            .summary-number {
              text-decoration: line-through;
            }
          }

          &.checkout-saving {
            .summary-number {
              color: $success-core;
              font-weight: 700;
            }
          }

          &.checkout-subtotal {
            .summary-number {
              text-decoration: line-through;
            }
          }

          &.checkout-voucher {
            .summary-number {
              color: $success-core;
              font-weight: 700;
            }
          }

          &.checkout-total {
            font-weight: 400;
            font-size: 1.125rem;

            .summary-text {
              text-transform: uppercase;
            }

            .summary-number {
              @include brand-property(color, brand-core);

              font-weight: 700;
            }
          }

          .summary-text {
            flex-wrap: wrap;
          }

          .summary-number {
            flex-shrink: 0;
            margin-left: 0.5rem;
          }

          .voucher-container {
            display: flex;
            align-items: center;
            font-size: 0.8125rem;

            .btn-icon-container {
              margin-right: 0.5rem;
              font-size: 1rem;
            }
          }
        }

        &.full-price {
          .summary-line-container {
            &.checkout-full-price {
              .summary-number {
                text-decoration: none;
              }
            }
          }
        }
      }

      .guarantee-safe-secure-container {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-only(sm) {
          flex-direction: row;
          justify-content: space-between;
          gap: 10px;

          .money-back-guarantee,
          .safe-and-secure {
            width: 48%;
            flex-grow: 1;
          }

          .safe-and-secure {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  // AGE RATING

  .age-rating-warning {
    margin-top: -0.5rem;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    max-width: 90%;

    .age-icon-container {
      margin-left: 0.75rem;

      img {
        width: 24px;
        height: 29px;

        @include media-breakpoint-up(md) {
          width: 28px;
          height: 34px;
        }
      }
    }
  }

  // CHECKOUT SMALLPRINT

  .checkout-smallprint {
    margin-bottom: 2rem;
  }

  // CHECKOUT RECENTLY VIEWED

  .checkout-recently-viewed {
    margin-top: 3rem;
    margin-bottom: 2rem;

    .container {
      padding: 0;
    }

    .view-all-btn {
      display: none;
    }
  }

  // CHECKOUT RECOMMENATIONS

  .checkout-recommendations {
    .container {
      padding: 0;
    }

    .HitCard {
      margin-bottom: 0;
    }

    .product-slider {
      margin-bottom: -3rem;
    }
  }

  .StickyCartSummary {
    position: fixed;
    bottom: 0;
    z-index: 1040;
    background-color: $grey-13;
    left: 0;
    right: 0;
    animation: fadein 0.5s;
    padding: 6px !important;
    box-shadow: 0 2px 20px rgba($black, 1);

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
      border-top: 1px solid $grey-2;
      box-shadow: map-get($light-theme, card-box-shadow);
    }

    .StickyCartSummary__trustpilotLogoContainer {
      padding: 0 1rem 1rem 1rem;

      a {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 200px;
          height: 23px;

          @include media-breakpoint-up(md) {
            width: 240px;
            height: 28px;
          }
        }
      }
    }
  }
}

.cart-drm-banner {
  background: $blue-grey-600;
  text-align: center;
  line-height: 22px;
  padding: 2px 14px;

  .btn {
    font-size: 0.875rem;
  }
}

.cart-drm-modal {
  text-align: center;

  .connection-icon {
    display: none;
  }

  .connection-unlink {
    margin-top: 6px;
  }

  h2 {
    font-weight: 600;
  }

  .close-button-container {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    margin: 5px;

    button {
      width: 100%;
      background: transparent;
      border: 0;
      padding: 0;
    }

    svg {
      color: $grey-4;
    }
  }
}
