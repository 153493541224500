@import "../../../styles/_chunk";

.StandardUpsell {
  .StandardUpsell__seeDetailsButton {
    color: $primary;
    background: none;
    padding: 0;
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 0.75rem;
    font-weight: 200;

    &:hover {
      text-decoration: underline;
    }
  }
}

.StandardUpsell__modalContainer {
  @include media-breakpoint-up(md) {
    max-width: 600px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 800px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 900px !important;
  }

  .modal-color {
    color: $grey-600;
  }

  .modal-body {
    padding: 2.5rem 1rem 1rem 1rem !important;

    @include media-breakpoint-up(md) {
      padding: 2.5rem !important;
    }
  }

  .modal-content {
    border-radius: $border-radius !important;
  }

  .StandardUpsellModal {
    .StandardUpsellModal__Carousel {
      .product-carousel {
        .slick-slide {
          width: 100%;
        }
      }

      margin-bottom: 1.5rem;

      .product-carousel-age-gating-container {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: $grey-13;
        color: $white;

        .age-gating {
          width: 100%;

          @include media-breakpoint-up(md) {
            width: unset;
          }
        }
      }
    }

    .StandardUpsell__bottomContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .StandardUpsell__name {
        color: $text-black;
      }

      .StandardUpsell__platformAndRatingContainer {
        display: flex;
        align-items: center;
        margin-bottom: 0.8rem;

        .StandardUpsell__ratingContainer {
          display: flex;
          align-items: center;

          .StandardUpsell__ratingText {
            font-weight: 400;
            color: $grey-5;
          }

          .star-rating-container {
            margin-right: 0.25rem;

            .star {
              svg {
                width: 16px;
                fill: $grey-4;

                &.star-half-icon {
                  width: 8px;
                }
              }

              &.highlighted-star {
                svg {
                  fill: $three-star-yellow;
                }
              }
            }
          }
        }

        .StandardUpsell__platformIconsContainer {
          .StandardUpsell__platformIconsContainer__os {
            color: $grey-600;
          }
        }
      }
    }
  }

  .StandardUpsellModal__commerceContainer {
    display: none;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    .btn-primary {
      margin-top: 0 !important;
    }
  }

  .cart-btn {
    width: fit-content;

    .btn-text-container {
      width: fit-content;

      @include media-breakpoint-down(lg) {
        width: inherit;
        text-align: center;
      }
    }
  }

  .badge {
    background: $grey-4;
    color: $text-black;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    padding: 0.325rem 0.875rem;
    white-space: pre;
  }

  .PriceContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: end;
    }

    .PriceContainer__price {
      @include brand-property(color, brand-core);

      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;

      b {
        font-weight: 700;
      }
    }

    .PriceContainer__wasPrice {
      font-size: 1.5rem;
      color: $grey-5;
      position: relative;
      text-decoration: line-through;
      margin-left: 0.75rem;
    }

    .PriceContainer__savingPercentage {
      color: $text-black;
      height: 40px;
      font-size: 1.125rem;
      padding: 8px;
      background-color: $grey-4;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      border-radius: $border-radius-sm;
      font-weight: 400;
    }
  }

  .mobile-pricing {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    justify-content: center;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }

    .PriceContainer {
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }

    .cart-btn {
      margin-top: 0 !important;

      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }
  }
}
