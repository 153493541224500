@import "../../styles/_chunk";

.checkout-usp-bar {
  background-color: $card-background-dark;
  border-radius: $border-radius-sm;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;

  @include theme(light) {
    background-color: map-get($light-theme, card-background);
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  .region-flag {
    width: 32px;
    height: 32px;
    margin-right: 0.5rem;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .usp-point {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    @include media-breakpoint-up(md) {
      width: 33%;
      font-size: 15px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 17px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 18px;
    }

    .icon-container {
      margin-right: 0.5rem;
    }

    .message-container {
      font-weight: 400;
      font-size: 1rem;
      line-height: 18px;
    }

    &.point-1 {
      padding: 0.75rem;
      padding-right: 0.25rem;

      @include media-breakpoint-up(lg) {
        padding: 1rem;
        padding-right: 0.5rem;
      }
    }

    &.point-2 {
      padding: 0.25rem;

      @include media-breakpoint-up(md) {
        padding: 0.75rem 0.25rem;
      }

      @include media-breakpoint-up(lg) {
        padding: 1rem 0.5rem;
      }
    }

    &.point-3 {
      padding: 0.75rem;
      padding-left: 0.25rem;

      @include media-breakpoint-up(lg) {
        padding: 1rem;
        padding-left: 0.5rem;
      }
    }
  }
}
