@import "../../styles/_chunk";

.coupon-section {
  margin-bottom: 2rem;
  font-size: 0.938rem;
  padding: 0 !important;
  border-radius: $border-radius;

  @include theme(light) {
    box-shadow: map-get($light-theme, card-box-shadow);
  }

  .voucher-form {
    background-color: $card-background-dark;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
    }
  }

  .coupon-toggle-button {
    padding: 1rem;
    background-color: $card-background-dark;
    border-radius: $border-radius;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
    }

    &:hover,
    &:focus {
      border: 0;
    }

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.reduced-side-padding {
      padding: 1rem 0.5rem;
    }
  }

  .coupon-toggle {
    cursor: pointer;
    justify-content: space-between;
    display: flex;
  }

  .coupon-input {
    background-color: $card-background-dark;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 1rem;
    padding-top: 0;

    @include theme(light) {
      background-color: map-get($light-theme, card-background);
    }

    input {
      border-color: map-get($light-theme, input-border-color);
      border-radius: $border-radius-sm;
      box-shadow: none;
    }
  }

  .coupon-eligible-box {
    background-color: $white;
    color: $text-black;
    text-align: left;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    border: 2px solid $grey-5;
    border-color: $warning-core;
    padding: 0.5rem;
    margin-bottom: 1rem;

    .coupon-eligible-button {
      padding: 5px;

      .btn-primary {
        width: 100%;
      }
    }

    .coupon-eligible-message {
      padding: 12px 10px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
