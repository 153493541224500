@import "../../styles/_chunk";

.RemoveItemModal {
  .modal-content {
    @include media-breakpoint-up(md) {
      max-width: 500px;
    }
  }

  .RemoveItemModel__buttonsContainers {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }

    .RemoveItemModel__button {
      width: 80%;
      text-transform: uppercase;
      font-weight: 700;

      @include media-breakpoint-up(sm) {
        width: 40%;
      }
    }

    .RemoveItemModel__removeButton {
      background-color: $grey-4 !important;
      border-color: $grey-4 !important;
      margin-top: 0.5rem;

      @include media-breakpoint-up(sm) {
        margin-left: 0.5rem;
        margin-top: 0;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: darken($grey-4, 10%) !important;
        border-color: darken($grey-4, 10%) !important;
      }
    }
  }
}
